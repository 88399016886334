




























































// Core
import { Component, Prop, Vue } from 'vue-property-decorator'

// Store
import { userNamespace } from '@store/user'

// Types
import { UserActionTypes } from '@store/user/Types'

// Interfaces
import { Task } from '@store/task/interfaces'
import { ITask } from '@/interfaces/Interface'

// Modules

@Component({
  name: 'TaskIn',

  components: {
    'v-form-change': () => import('@/components/forms/FormChange.vue'),
    'v-dialog-task-info': () => import('@/components/dialogs/DialogTaskInfo.vue'),
    'v-dialog-task-execute': () => import('@/components/dialogs/DialogTaskExecute.vue'),
  },
})
export default class TaskInComponent extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
  })
  private task!: Task

  @userNamespace.State('selectedProject')
  private selectedProject!: number

  @userNamespace.Action(UserActionTypes.A_FINISH_TASK_IN)
  private finishTask!: (params) => Promise<any>

  private visibleDialogTaskInInfo: boolean = false
  private visibleDialogTaskOutInfo: boolean = false
  private visibleDialogTaskExecute: boolean = false

  private handleCompleteTask() {
    this.finishTask({ taskID: this.task.id, selectedProject: this.selectedProject })
      .then(() => {
        this.$notify({
          title: 'Выполнено',
          message: 'Действие успешно выполнено',
          type: 'success',
        })
      })
      .catch(error => {
        this.$notify.error({
          title: 'Ошибка',
          message: error.response.data.message,
        })
      })
  }
}
